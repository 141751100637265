@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.valueText {
    @include font-standard-bold;
    color: $primary-green-500;
}


.mobileHeader {
    @media (max-width: $screen-lg) {
        position: relative;
        margin-top: 1rem;



        &::before {
            content: attr(data-header);
            display: block;
            font-size: 12px;

            position: absolute;
            top: -1.5em;
            left: 0;
            width: 100%;
            text-align: left;
            font-weight: normal;

            @include helper-text;
        }

        &.contentRight {
            &::before {
                right: 0;
                left: unset;
                text-align: right
            }
        }

        &.invoicePrice {
            margin-top: 1.5rem;
            @include font-standard-bold;
            font-size: 1.5rem;
    
            &::before {
                top: -1.5em;
            }
        }
    }

    
}

.mobileInvoiceAccordion {
    border: 1px solid $secondary-black-200;
}

.acceptedCardsContainer {
    width: 100%;
    height: 25px;
    text-align: center;

    @media (min-width: $screen-md){
        height: 50px;
        position: absolute;
        top: -50px;
    }
}