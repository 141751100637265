@import 'variables.scss';

/** FONT MIXINS start */
@mixin font-standard {
  font-family: $font-quicksand-variable;
  font-size: 16px;
  line-height: 26px;
  color: $secondary-black-400;
}

@mixin font-standard-important {
  font-family: $font-quicksand-variable !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: $secondary-black-400 !important;
}

@mixin font-standard-bold {
  @include font-standard;
  font-weight: bold;
}

@mixin font-small-bold {
  font-family: $font-quicksand-variable;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: $secondary-black-400;
}

@mixin font-small-bold-important {
  font-family: $font-quicksand-variable !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: bold !important;
  color: $secondary-black-400 !important;
}

@mixin font-description-standard {
  font-family: $font-quicksand-variable;
  font-size: 12px;
  color: rgba($secondary-black-400, 0.6);
}

@mixin standalone-link {
  font-family: $font-quicksand-variable;
  font-size: 12px;
  line-height: 16px;
  color: $secondary-black-400;
  font-weight: bold;
}

@mixin helper-text {
  font-family: $helper-text-font-family;
  font-size: 12px;
  line-height: 18px;
  color: $secondary-black-400;
}

@mixin validation-error-helper-text {
  @include helper-text;
  color: $interactive-red;
}

@mixin h2 {
  font-family: $font-quicksand-variable;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: $margin-bottom;
  font-weight: bold;

  @media (max-width: $screen-lg) {
    font-size: 28px;
    line-height: 38px;
  }

  @media (max-width: $screen-md) {
    font-size: 26px;
    line-height: 36px;
  }

  @media (max-width: $screen-sm) {
    font-size: 23px;
    line-height: 34px;
  }
}

@mixin h2-important {
  font-family: $font-quicksand-variable !important;
  font-size: 32px !important;
  line-height: 40px !important;
  margin-bottom: $margin-bottom !important;
  font-weight: bold !important;

  @media (max-width: $screen-lg) {
    font-size: 28px !important;
    line-height: 38px !important;
  }

  @media (max-width: $screen-md) {
    font-size: 26px !important;
    line-height: 36px !important;
  }

  @media (max-width: $screen-sm) {
    font-size: 23px !important;
    line-height: 34px !important;
  }
}

@mixin h3 {
  font-family: $font-quicksand-variable;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: $margin-bottom;
  font-weight: bold;

  @media (max-width: $screen-lg) {
    font-size: 22px;
    line-height: 30px;
  }

  @media (max-width: $screen-md),
  (max-width: $screen-sm) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin h3-important {
  font-family: $font-quicksand-variable !important;
  font-size: 22px !important;
  line-height: 30px !important;
  margin-bottom: $margin-bottom !important;
  font-weight: bold !important;

  @media (max-width: $screen-lg) {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  @media (max-width: $screen-md),
  (max-width: $screen-sm) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

/** FONT MIXINS end */

/** BUTTON MIXINS start */
@mixin btn-base {
  font-family: $font-quicksand-variable;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 32px;
  background-color: $primary-green-400;
  border-radius: 999px;
  text-decoration: none !important;
  color: $accent-white;
  display: inline-block;
}

@mixin btn-base-important {
  font-family: $font-quicksand-variable !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 16px 32px !important;
  background-color: $primary-green-400 !important;
  border-radius: 999px !important;
  text-decoration: none !important;
  color: $accent-white !important;
  display: inline-block !important;
}

@mixin btn-base-w100p {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@mixin btn-small {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

@mixin btn-hover {
  &:hover {
    background-color: $primary-green-300;
  }
}

@mixin btn-hover-important {
  &:hover {
    background-color: $primary-green-300 !important;
  }
}

@mixin btn-focus {
  &:focus {
    outline: 2px solid $interactive-blue;
    outline-offset: 2px;
  }
}

@mixin btn-active {
  &:active {
    background-color: $primary-green-500;
  }
}

@mixin btn-focus-important {
  &:focus {
    outline: 2px solid $interactive-blue !important;
    outline-offset: 2px !important;
  }
}

@mixin btn-active-important {
  &:active {
    background-color: $primary-green-500 !important;
  }
}

@mixin btn-disabled {

  &:disabled,
  [aria-disabled="true"] {
    background-color: $primary-green-400;
    opacity: 0.4;
  }
}

@mixin btn-chevron-normal-defaults {
  content: '';
  background-image: url('/assets/img/icons/chevron.svg');
  background-repeat: no-repeat;
}

@mixin btn-chevron-w100p-defaults {
  content: '';
  width: 16px;
  height: 16px;
  background-image: url('/assets/img/icons/chevron.svg');
  background-repeat: no-repeat;
  background-position: 0px;
  position: absolute;
}

@mixin btn-chevron-left {
  &::before {
    @include btn-chevron-normal-defaults;
    padding-left: 22px;
    background-position: 0px;
  }
}

@mixin btn-chevron-left-w100p {
  &::before {
    @include btn-chevron-w100p-defaults;
    left: 35px;
  }
}

@mixin btn-chevron-right {
  &::after {
    @include btn-chevron-normal-defaults;
    padding-right: 20px;
    background-position: 13px;
  }
}

@mixin btn-chevron-right-w100p {
  &::after {
    @include btn-chevron-w100p-defaults;
    right: 25px;
  }
}

@mixin btn-black {
  background-color: $secondary-black-400;

  &:hover {
    background-color: $secondary-black-300;
  }

  &:active {
    background-color: $secondary-black-500;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: $secondary-black-400;
    opacity: 0.4;
  }
}

@mixin btn-black-important {
  background-color: $secondary-black-400 !important;

  &:hover {
    background-color: $secondary-black-300 !important;
  }

  &:active {
    background-color: $secondary-black-500 !important;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: $secondary-black-400 !important;
    opacity: 0.4 !important;
  }
}

@mixin btn-white {
  background-color: $accent-white;
  color: $secondary-black-400;

  &:hover {
    background-color: $accent-grey;
  }

  &:active {
    background-color: $accent-grey;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: $accent-white;
    opacity: 0.4;
  }

  &.chevron-left,
  &.chevron-right {
    filter: invert(1);
  }
}

@mixin btn-dark-contrast {
  background-color: unset;
  border: solid 1px $secondary-black-400;
  color: $secondary-black-400;
  padding: calc(16px - 2px) calc(32px - 2px);


  &:hover {
    background-color: $secondary-black-500;
    color: $accent-white;
  }

  &:active {
    background-color: $secondary-black-400;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: unset;
    border: solid 1px $secondary-black-400;
    color: $secondary-black-400;
    opacity: 0.4;
  }

  &.chevron-left,
  &.chevron-right {
    filter: invert(1);

    &:hover,
    &:active {
      filter: none;
    }
  }
}

@mixin btn-dark-contrast-important {
  background-color: unset !important;
  border: solid 1px $secondary-black-400 !important;
  color: $secondary-black-400 !important;
  padding: calc(16px - 2px) calc(32px - 2px);

  &:hover {
    background-color: $secondary-black-500 !important;
    color: $accent-white !important;
  }

  &:active {
    background-color: $secondary-black-400 !important;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: unset !important;
    border: solid 1px $secondary-black-400 !important;
    color: $secondary-black-400 !important;
    opacity: 0.4 !important;
  }

  &.chevron-left,
  &.chevron-right {
    filter: invert(1) !important;

    &:hover,
    &:active {
      filter: none !important;
    }
  }
}

@mixin btn-light-contrast {
  background-color: unset;
  border: solid 1px $accent-white;
  color: $accent-white;
  padding: calc(16px - 2px) calc(32px - 2px);

  &:hover {
    background-color: $accent-white;
    color: $secondary-black-400;
  }

  &:active {
    background-color: $accent-white;
    color: $secondary-black-400;
  }

  &:disabled,
  [aria-disabled="true"] {
    background-color: unset;
    border: solid 1px $accent-white;
    color: $accent-white;
    opacity: 0.4;
  }
}

/** BUTTON MIXINS end */

/** FORM MIXINS start */

/** CHECKBOX MIXINS start */
@mixin checkbox-default() {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border: 1px solid $secondary-black-400;
  background-color: transparent;
  border-radius: 4px;
  display: inline-block;
  // margin-right: 8px;
  position: relative;
  margin-top: 2px;

  &:hover {
    background-color: $secondary-black-400 !important;

    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    background-color: transparent !important;
    outline: 2px solid $interactive-blue;
    outline-offset: 2px;
    border-radius: 4px;
  }

  &:active {
    background-color: $secondary-black-400;
  }
}

@mixin checkbox-default-checked() {
  background-color: transparent;
  background-image: url('/assets/img/icons/check_black.svg');
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background-image: url('/assets/img/icons/check_white.svg');
  }
}

@mixin checkbox-styles() {
  .checkbox-wrapper {
    // display: flex;
    // align-items: center;

    input[type="checkbox"] {
      display: none; // Hide the actual checkbox input
    }

    input[type="checkbox"]:checked+.custom-checkbox {
      @include checkbox-default-checked();
      // background-color: transparent;
      // background-image: url('/assets/img/icons/check_black.svg');
      // background-size: 16px 16px;
      // background-position: center;
      // background-repeat: no-repeat;

      // &:hover {
      //   background-image: url('/assets/img/icons/check_white.svg');
      // }
    }

    .custom-checkbox {
      @include checkbox-default();
    }

    .checkbox-content {
      display: inline-block; // Ensure the content does not take full width
      @include font-standard;
    }
  }
}

@mixin checkbox-styles-white() {
  @include checkbox-styles();

  input[type="checkbox"]:checked+.custom-checkbox {
    background-color: transparent;
    background-image: url('/assets/img/icons/check_white.svg');

    &:hover {
      background-image: url('/assets/img/icons/check_black.svg');
    }
  }

  .custom-checkbox {
    border: 1px solid $accent-white;
    background-color: transparent;

    &:hover {
      background-color: $accent-white !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    &:active {
      background-color: $accent-white;
    }
  }
}

/** CHECKBOX MIXINS end */

/** RADIO BUTTON MIXINS start */
@mixin radiobutton-default() {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border: 1px solid $secondary-black-400;
  background-color: transparent;
  border-radius: 9999px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $secondary-black-400 !important;

    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    background-color: transparent !important;
    outline: 2px solid $interactive-blue;
    outline-offset: 2px;
    border-radius: 4px;
  }

  &:active {
    background-color: $secondary-black-400;
  }
}

@mixin radiobutton-default-checked() {
  // background-color: $secondary-black-400;
  position: relative;
  border: 1px solid $secondary-black-400;

  &::before {
    content: '';
    position: absolute;
    top: 2px; 
    left: 2px; 
    right: 2px; 
    bottom: 2px; 
    background-color: $secondary-black-400; 
    border-radius: 50%; 
  }

  &:hover {
    &::before {
      border: none;
      background-color: $secondary-black-400;
    }
  }


}

@mixin radiobutton-styles() {
  .radiobutton-wrapper {
    input[type="radio"] {
      display: none; // Hide the actual checkbox input
    }

    input[type="radio"]:checked+.custom-radiobutton {
      @include radiobutton-default-checked();
    }

    .custom-radiobutton {
      @include radiobutton-default();
    }

    .radiobutton-content {
      display: inline-block; // Ensure the content does not take full width
      @include font-standard;
    }
  }
}

@mixin radiobutton-styles-white() {
  @include radiobutton-styles();

  input[type="radio"]:checked+.custom-radiobutton {
    background-color: transparent !important;
    border: 1px solid $accent-white !important;

    &::before {
      background-color: $accent-white !important; 
      border-radius: 50%; 
    }
  
    &:hover {
      &::before {
        border: none;
        background-color: $accent-white !important;
      }
    }
  }

  .custom-radiobutton {
    border: 1px solid $accent-white !important;
    background-color: transparent;

    &:hover {
      background-color: $accent-white !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    &:active {
      background-color: $accent-white !important;
    }
  }
}

/** RADIO BUTTON MIXINS end */

/** FORM MIXINS end */

/** TEXT MIXINS start */
@mixin footer-link {
  @include font-standard;
  font-size: 14px;
  margin-bottom: unset;
  color: $accent-white;

  &:hover {
    text-decoration: underline;
  }
}

@mixin footer-header {
  @include font-standard;
  font-size: 14px;
  margin-bottom: unset;
  color: $accent-white;
  font-weight: bold;
}

@mixin truncate-multiline($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}