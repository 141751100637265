@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.recruitAFriendContainer {
    .currentProfile {
        @include font-standard;
    }
}

.recruitLink {
  color: $secondary-orange-400 !important;
}
