@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.profileContainer {
    .currentProfile {
        @include font-standard;
    }
}

.changeProfileButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: $accent-white;
    }

    img,
    .icon-switch,
    .icon-profile {
        width: 16px; /* Adjust the size as needed */
        height: 16px; /* Adjust the size as needed */
    }
}