/** SCREEN VARIABLES */
$screen-sm: 639px;
$screen-md: 1023px;
$screen-lg: 1279px;
$screen-xl: 1600px;


/** FONT VARIABLES */
$font-quicksand-variable: 'Quicksand Variable', sans-serif;
$font-quicksand-bold: 'Quicksand Bold', sans-serif;
$font-quicksand-light: 'Quicksand Light', sans-serif;
$font-quicksand-medium: 'Quicksand Medium', sans-serif;
$font-quicksand-regular: 'Quicksand Regular', sans-serif;
$font-quicksand-semibold: 'Quicksand SemiBold', sans-serif;
$font-bitterbrush-regular: 'Bitterbrush Regular', sans-serif;

$heading-font-family: 'Bitterbrush Regular', sans-serif;
$helper-text-font-family: 'OpenSans Regular', sans-serif;

/** COLOR VARIABLES */
$primary-green-500: #468325;
$primary-green-400: #6CC83B;
$primary-green-300: #94DB6E;
$primary-green-200: #C4E9B1;
$primary-green-100: #D3EFC4;
$primary-green-50: #f0faeb;

$secondary-orange-500: #D6891A;
$secondary-orange-400: #F79E1C;
$secondary-orange-300: #F9C67A;

$secondary-black-500: #000000;
$secondary-black-400: #181818;
$secondary-black-300: #666666;
$secondary-black-200: #A3A3A3;
$secondary-black-100: #CCCCCC;
$secondary-black-50: #ECECEC;

$accent-grey: $secondary-black-50;
$accent-white: #FFFFFF;
$accent-light-grey: #F4F4F4;
$accent-light-blue: #e1efef;

$border-grey: $secondary-black-100;

$interactive-blue: #1821FB;
$interactive-red: #FF3E32;
$interactive-green: $primary-green-400;

$background-color-order-green: $primary-green-100;

/** TEXT VARIABLES */
$margin-bottom: 16px;


/** LINK VARIABLES */
$outside-vertical-padding: 5px;
$outside-horisontal-padding: 5px;
$chevron-padding: 8px;
$chevron-width: 24px;