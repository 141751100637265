@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.logoutButton {
    img {
        width: 16px;
        height: 16px;
        max-width:unset;
    }

    .logoutIconHover {
        display: none;
    }

    .logoutIcon {
        display: inline-block;
    }


    &:hover {
        .logoutIconHover {
            display: inline-block;
        }

        .logoutIcon {
            display: none;
        }
    }
}