.faqBlock {
    display: inline-flex;
    align-content: center;
    // padding: 3rem 3rem 3rem 3rem;
    

    .expandableText {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
    }

    .expandableText.expanded {
        margin-top: 16px;
        max-height:max-content;
        opacity: 1;
    }   

    h3{
        margin-bottom: 0;
    }
}
